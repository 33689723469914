.c-accordion {
    list-style: none;
    margin: 2em 0;
    
    // background-color: lavender;
}
.c-accordion__item {
    display: block;
    margin-left: 0;
    .clearfix;
    border-top: 1px solid @line-grey;
    
    &:last-of-type {
        border-bottom: 1px solid @line-grey;
    }
    
    .has-js &.is-open {
        .c-accordion__heading::after {
            transform: rotate(-90deg);
        }
    }
    
    // background-color: lightcyan;
}
.c-accordion__heading {
    position: relative;
    display: block;
    font-size: 1.4em;
    font-weight: normal;
    margin-bottom: 0;
    padding: 0.75em 2em 1em 0.75em;
    transition: color @t-basic;
    
    &:hover,
    &:focus,
    &:active,
    .has-js .is-open & {
        color: @c3p-blue;
    }
    
    .c-accordion--green &:hover,
    .c-accordion--green &:focus,
    .c-accordion--green &:active,
    .has-js .c-accordion--green .is-open & {
        color: @c3p-green;
    }
    
    .has-js & {
        cursor: pointer;
        
        &::after {
            .link-arrow(0.85em; 0.5em);
            top: 1em;
            right: 0.5em;
            transform: rotate(90deg);
            transition: transform @t-slow;
        }
        
    }
    
    // background-color: whitesmoke;
}
.c-accordion__body {
    .has-js & {
        // display: none;
        max-height: 0;
        overflow: hidden;
        transition: max-height @t-slow ease-in-out;
    }
    // .has-js .is-open & {
    //     display: block;
    // }
}
.c-accordion__body-inner {
    // display: block;
    padding: 0.75em 1rem 1em;
    
    // background-color: ivory;
}
