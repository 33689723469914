.btn {
  display: inline-block;
  width: auto;
  padding: 0.425em 0.8em;
  border-width: 0;
  border-radius: 0.25rem;
  box-shadow: none;

  background-color: @black-80;
  color: #fff;

  transition: background-color @t-basic, color @t-basic;

  &:hover,
  &:focus {
    box-shadow: none;
    background-color: @black-55;
  }
  &:active {
    box-shadow: none;
    background-color: @black-70;
  }
}

.btn--sm {
  font-size: 0.85em;
}
.btn--lg {
  font-size: 1.25em;
}

.btn--light {
  background-color: @black-10;
  color: @black-80;

  &:hover,
  &:focus {
    background-color: @black-20;
    // color: #fff;
  }
  &:active {
    background-color: @black-40;
    color: #fff;
  }
}
.btn--white {
  background-color: #fff;
  color: @black-80;

  &:hover,
  &:focus {
    background-color: @black-15;
  }
  &:active {
    background-color: @black-20;
  }
}
.btn--primary {
  background-color: @c3p-blue-dark;

  &:hover,
  &:focus {
    background-color: @c3p-blue;
  }
  &:active {
    background-color: @c3p-blue-darker;
  }
}
.btn--success {
  background-color: @c3p-green;

  &:hover,
  &:focus {
    background-color: @c3p-green-light;
  }
  &:active {
    background-color: @c3p-green-dark;
  }
}
.btn--warning {
  background-color: @c3p-red;

  &:hover,
  &:focus {
    background-color: @c3p-red-light;
  }
  &:active {
    background-color: @c3p-red-dark;
  }
}

.btn--primary-ghost {
  background-color: transparent;
  color: @c3p-blue-dark;
  border: 1px solid @c3p-blue;

  &:hover,
  &:focus {
    background-color: transparent;
    color: @c3p-blue;
    border-color: @c3p-blue;
  }
  &:active {
    background-color: transparent;
    color: @c3p-blue-dark;
  }
}
.btn--success-ghost {
  background-color: transparent;
  color: @c3p-green-dark;
  border: 1px solid @c3p-green;

  &:hover,
  &:focus {
    background-color: transparent;
    color: @c3p-green;
    border-color: @c3p-green;
  }
  &:active {
    background-color: transparent;
    color: @c3p-green-dark;
  }
}
