.c-overlay-backdrop {
    position: fixed;
    bottom: 100%;
    opacity: 0;
    
    transition: opacity @t-slow ease-in-out;
    
    &.is-open {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: @z-overlay-backdrop;
        opacity: 1;
        background-color: rgba(0,0,0,0.5);
    }
}
