h1,
h2 {
    * + & {
        margin-top: 0.75em;
    }
}
h3,
h4 {
    * + & {
        margin-top: 1em;
    }
}
h1 + h1,
h1 + h2,
h2 + h2,
h2 + h3,
h3 + h3,
h3 + h4,
h4 + h4 {
    margin-top: 0;
}

// Making classes for heading styles as well is enormously useful for
// keeping good doc hierarchy and easy application of text fanciness

h1, .h1 {
    font-family: @display-font-stack;
    line-height: @heading-line-height;
    font-size: 2.7em;
    font-size: ~'calc(2.4em + 1.6 * ((100vw - 300px) / 200))';
    font-weight: 300;
}
h2, .h2 {
    font-family: @display-font-stack;
    line-height: @heading-line-height;
    font-size: 2.3em;
    font-size: ~'calc(2em + 1.1 * ((100vw - 300px) / 200))';
    font-weight: 300;
}
.h2-sm { // lol
    font-family: @display-font-stack;
    line-height: @heading-line-height;
    font-size: 1.8em;
    font-size: ~'calc(1.7em + 1.1 * ((100vw - 300px) / 200))';
    font-weight: 300;
}
h3, .h3 {
    font-family: @display-font-stack;
    line-height: @heading-line-height + .1;
    font-size: 1.6em;
    // font-size: ~'calc(1.4em + 0.8 * ((100vw - 300px) / 200))';
    font-weight: 300;
    letter-spacing: 0.01em;
}
h4, .h4 {
    font-family: @display-font-stack;
    line-height: @heading-line-height + .2;
    font-size: 1.2em;
    font-weight: normal;
    letter-spacing: 0.01em;
}
h5, .h5 {
    font-family: @display-font-stack;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0.01em;
}
h6, .h6 {
    font-size: 1em;
    font-weight: normal;
    font-style: italic;
}
