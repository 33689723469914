.site-footer {
    display: block;
    flex: 0 0 auto;
    
    color: #fff;
    background-color: @black-80;
    
    a {
        color: #fff;
    }
}
