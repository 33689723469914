.scroll-container-horiz {
  overflow-x: scroll;

  @background-color: rgba(255, 255, 255, 1);
  @shadow-color: hsla(0, 0%, 0%, 0.35);
  @shadow-size: 0.5rem;
  @transparent: rgba(255, 255, 255, 0);

  background:
    linear-gradient(to right, @background-color, @background-color, @transparent (@shadow-size * 2)),
    radial-gradient(farthest-side at 0 50%, @shadow-color, @transparent),
    linear-gradient(to left, @background-color, @background-color, @transparent (@shadow-size * 2)),
    radial-gradient(farthest-side at 100% 50%, @shadow-color, @transparent) 100%
    ;
  background-color: @background-color;
  background-repeat: no-repeat;
  background-attachment: local, scroll, local, scroll;
  background-size: 100% 100%, @shadow-size 100%, 100% 100%, @shadow-size 100%;
  height: 100%;
}

.scroll-container__inner {
  margin: 0 auto;
  width: 100%;

  // * {
  //   background-blend-mode: multiply;
  // }
}
