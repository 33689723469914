.c-img-banner {
    position: relative;
    .display-flex;
    flex-direction: column;
    overflow: hidden;
}

.c-img-banner--dark {
    background-color: @black-80;
    color: #fff;
}

.c-img-banner__img {
    display: inline-block;
    height: 10em;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    order: -1;
}
.c-img-banner__img--boy-waiting-room {
    background-image: url('@{image-path}nfa-stock/91ca9fae1209bb3e3e63e9a25a7c967bced30cf8-boy-waiting-room.jpg');
    background-position: 80% 35%;
    background-size: 200%;
}
.c-img-banner__img--girl-ballerina-window {
    background-image: url('@{image-path}nfa-stock/585e87c95c8e160ee944804ee0416ecd38eabac1-girl-ballerina-window-cropped.jpg');
    background-position: center;
    background-size: contain;
    height: 14em;
}
.c-img-banner__img--child-knight-costume {
    background-image: url('@{image-path}nfa-stock/895ca00f3c77a7716f0486257093df5b8b6af8b8-child-knight-costume.jpg');
    background-position: 75% center;
    background-size: cover;
    background-color: #fff;
}
.c-img-banner__img--placeholder {
    background-image: url('@{image-path}placeholder-kitten.jpg');
    background-position: center;
    background-size: cover;
}

.c-img-banner__text {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1.25em;
    color: inherit;
}



@media screen and (min-width: 40em) {
    .c-img-banner__img {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .c-img-banner__img--boy-waiting-room {
        background-size: cover;
    }
    .c-img-banner__img--girl-ballerina-window {
        background-size: 22em;
        background-position: 0 center;
        top: 2.5em;
        bottom: 3em;
        height: auto;
    }
    .c-img-banner__text {
        width: 55%;
        padding-top: 4em;
        padding-bottom: 4.5em;
    }
    .c-img-banner__text--right-aligned {
        width: 45%;
        margin-left: auto;
        margin-right: 3vw;
        text-align: right;
    }
    .c-img-banner--programs__text {
        width: 70%;
        padding-top: 3em;
        padding-bottom: 3.5em;
    }
}
@media screen and (min-width: 65em) {
    .c-img-banner__img--girl-ballerina-window {
        background-size: contain;
        background-position: 10% center;
    }
    .c-img-banner__text--right-aligned {
        margin-right: 4em;
    }
}
