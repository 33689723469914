.c-action-feature {
    counter-reset: li;
    list-style: none;
    margin: 3em 0 0;
}

.c-action-feature__category {
    position: relative;
    display: block;
    margin-left: 0;
    margin-bottom: 2.5rem;
    padding: 6.5em 0 0;
    
    &::before {
        position: absolute;
        display: block;
        // content: counter(li);
        // counter-increment: li;
        content: '';
        // font-family: @display-font-stack;
        // font-size: 2.5em;
        // line-height: 1;
        // padding: 0.5em 0.5em 0.7em;
        height: 5.5em;
        top: 0;
        width: 100%;
        max-width: 20rem;
        text-align: right;
        background-color: @black-80;
        background-repeat: no-repeat;
        background-position: right top 0.5em;
    }
}

.c-action-feature__heading {
    font-weight: normal;
    text-transform: uppercase;
}

.c-action-feature__category--identify {
    .c-action-feature__heading { color: @black-80; }
    &::before {
        background-color: @black-80;
        background-image: url('@{image-path}icons/icon-ctip-exclm-white.svg');
    }
}
.c-action-feature__category--speak-up {
    .c-action-feature__heading { color: @c3p-blue-dark; }
    &::before {
        background-color: @c3p-blue-dark;
        background-image: url('@{image-path}icons/icon-c3p-kid-white.svg');
    }
}
.c-action-feature__category--reach-out {
    .c-action-feature__heading { color: @c3p-green; }
    &::before {
        background-color: @c3p-green;
        background-image: url('@{image-path}icons/icon-c3p-kid-yay-white.svg');
    }
}

.c-action-feature__list {
    list-style: none;
    font-size: 1.2em;
    font-family: @display-font-stack;
    letter-spacing: 0.015em;
    margin-left: 0;
}

.c-action-feature__link {
    display: block;
    line-height: 1.4;
    padding: 0.9em 1.5em 0.9em 0;
    color: @black-55;
    text-decoration: none;
    box-shadow: none;
    background-image: none;
    border-bottom: 1px solid @black-20;
    
    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        color: @black-80;
    }
    
    &::after {
        position: absolute;
        display: block;
        content: '';
        top: 1.4em;
        left: auto;
        right: 0.6em;
        width: 0.6em;
        height: 0.6em;
        background-color: transparent;
        border: solid @black-80;
        border-width: 2px 2px 0 0;
        transform: rotate(45deg);
        transition: right @t-snappy;
    }
    &:hover::after,
    &:focus::after,
    &:active::after {
        background-color: transparent;
        right: 0.35em;
    }
}
.c-action-feature__link-heading {
    font-size: 1.1em;
    font-weight: normal;
    margin-bottom: 0.5em;
    color: @black-80;
}
.c-action-feature__goto {
    font-size: 0.9em;
}
.c-action-feature__link-desc {
    // font-size: 0.85em;
    // font-weight: 300;
    // letter-spacing: 0.02em;
    
    font-family: @body-font-stack;
    line-height: @body-line-height;
    font-size: 0.7em;
    letter-spacing: 0;
    
    margin-bottom: 0;
}

.c-action-feature__link--learnmore {
    font-size: 1.2em;
    color: @black-80;
    border-bottom: none;
    
    &::after {
        .link-arrow-dark(@bg-size: 0.45em);
        top: 0.9em;
        right: 0.15em;
        border-width: 0;
        transform: rotate(0deg);
    }
    &:hover::after,
    &:focus::after,
    &:active::after {
        background-color: @black-80;
        right: -0.1em;
    }
}



@media screen and (min-width: 26em) {
    .c-action-feature__category {
        padding: 0 0 0 7.5em;
        
        &::before {
            left: 0;
            width: 6em;
            background-size: 60%;
        }
    }
}
@media screen and @breakpoint-lg {
    .c-action-feature__category {
        display: inline-block;
        vertical-align: top;
        padding: 6.5em 0 0;
        width: 30%;
        width: ~'calc(33.3% - 2em)';
        margin-right: 3em;
        margin-bottom: 0;
        
        &:last-of-type {
            margin-right: 0;
        }
    }
    .c-action-feature__description {
        // min-height: 9.5em;
    }
}



// @supports (display: grid) {
//     .c-action-feature {
//         display: grid;
//         grid-gap: 2rem;
//         grid-template-columns: repeat(3, 1fr);
//     }
//     .c-action-feature__category {
//         display: grid;
//         grid-template-rows: repeat(2, minmax(4em, 1fr));
//         width: auto;
//         margin-right: 0;
//     }
// }
