.c-footnotes {
  font-size: 0.85em;
  margin-top: 1.5em;
  margin-left: 0;
  list-style: none;
}
.c-footnote {
  position: relative;
  padding: 0.65rem 0 0.75rem 1.75em;
  padding-left: ~'calc(1.75em + 1 * ((100vw - 300px) / 200))';

  &:target {
    animation: 2s footnote-bg;
  }
}
@keyframes footnote-bg {
  0% {
    background-color: transparent;
  }
  5%, 50% {
    background-color: rgba(116,141,185,0.2);
  }
  100% {
    background-color: transparent;
  }
}

.c-footnote__id {
  position: absolute;
  left: 0;
  width: 1.5em;
  text-align: right;
}
.c-footnote__return {
  margin-left: 0.2em;
}

.c-footnote__ref {
  margin: 0 0.1em;
  padding: 0 0.1em;
  color: inherit;
}
