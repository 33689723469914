.c-search-field {
    margin-top: 1em;
    .display-flex;
    align-items: stretch;
}
.c-search-field__input {
    flex: 1 1 auto;
    margin-bottom: 0;
}
.c-search-field__btn {
    min-height: 2.7em;
    width: 2.7em;
    min-height: ~'calc(2.5em + 2px)';
    width: ~'calc(2.5em + 2px)';
    margin-left: 2px;
    flex: none;
    
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: url('@{image-path}icons/icon-search-white.svg');
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;
}
