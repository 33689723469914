@media print {
    
    // @c3p-green:             @black-60;
    // @c3p-green-dark:        @black-70;
    // @c3p-green-light:       @black-55;
    // 
    // @c3p-blue:              @black-60;
    // @c3p-blue-dark:         @black-70;
    // @c3p-blue-darker:       @black-80;
    // @c3p-blue-light:        @black-55;
    // 
    // @c3p-red:               @black-60;
    // @c3p-red-dark:          @black-70;
    // @c3p-red-light:         @black-55;
    // 
    // @mk-yellow:             @black-55;
    
    
    @page {
        margin: 1.5cm;
    }
    
    html {
        font-size: 12pt !important;
    }
    body {
        overflow: visible;
        font-size: 12pt !important;
    }
    
    .site-footer {
        display: none;
    }
    
    abbr {
        text-decoration: none !important;
        border-bottom: none !important;
    }
    
    img {
        max-width: 400px!important;
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }
    
    
    a {
        color: #000!important;
        font-weight: bold!important;
        text-decoration: underline;
        box-shadow: none;
    }
    
    .block-link {
        text-transform: none !important;
        font-style: normal;
    }
    
    // display links
    
    a::after {
        width: auto;
        content: " (https://protectchildren.ca" attr(href) ") ";
    }
    [lang="fr"] a::after {
        width: auto;
        content: " (https://protegeonsnosenfants.ca" attr(href) ") ";
    }
    a[href*="://"]::after,
    [lang="fr"] a[href*="://"]::after {
        width: auto;
        content: " (" attr(href) ") ";
    }
    
    // but not these
    a[href^="#"]::after,
    a[href^="tel:"]::after,
    a[href^="mailto:"]::after,
    a[href^="javascript:"]::after {
        width: auto;
        content: ""!important;
    }
    
}
