.list--simple {
  list-style: none;
  margin-left: 0;
}
.list--inline {
  list-style: none;
  margin-left: 0;
  
  > li {
    display: inline-block;
  }
}
.list--spaced {
  li {
    margin-bottom: 1.25rem;
  }
}
.list--spaced-sm {
  li {
    margin-bottom: 0.25em;
  }
}



@media screen and (min-width: 26em) {
  .list--columns {
    column-count: 2;
    -moz-column-count: 2;
    column-gap: 2em;
    -moz-column-gap: 2em;

    li {
      overflow: hidden;
      break-inside: avoid;
    }
  }
}
