html {
  font-size: 100%; // 16px base fallback
  font-size: ~'calc(1em + 1.1 * ((100vw - 500px) / 500) )';
  line-height: @body-line-height;
  height: 100%;
  // overflow-x: hidden;
}
body {
  position: relative;
  font-family: @body-font-stack;
  font-style: normal;
  font-weight: normal;
  color: @grey-darker;

  .display-flex;
  flex-direction: column;
  // -webkit-box-orient: vertical;
  //  -webkit-box-direction: normal;
  //     -ms-flex-direction: column;
  // -webkit-flex-direction: column;

  height: 100%;
  // overflow-x: hidden;

  &.nav-menu-open {
    overflow: hidden;
  }
}
section {
  position: relative;
}
b, strong {
  font-weight: bold;
}
i, em, cite {
  font-style: italic;
}
sup {
  font-size: 0.7em;
  text-transform: lowercase;
}
abbr {
  // font-size: 0.92em;
  // letter-spacing: 0.02em;
  text-decoration: none;
  border-bottom: none;

  &[title] {
    cursor: help;
    text-decoration: underline dotted @black-40;
    // text-decoration: none;
    // border-bottom: 1px dotted @black-40;
  }
}
address {
  font-style: normal;
}
button {
  font-family: inherit;
  word-break: break-word;
  word-wrap: break-word;
  cursor: pointer;
}
hr {
  margin: 2rem 0;
  height: 0;
  border: solid @line-grey;
  border-width: 1px 0 0;

  &.rule--light {
    border-color: #fff;
  }
  &.rule--dark {
    border-color: @black-70;
  }
  &.rule--less-margin {
    margin: 1.25rem 0;
  }
}
pre {
  overflow-x: scroll;
}
figure.table-container {
  margin: 2em 0;
  padding: 0;
  max-width: 100%;
  overflow-x: scroll;

  > table {
    margin: 0;
  }
}

// keep that good base rhythm
// https://csswizardry.com/2012/06/single-direction-margin-declarations/
h1, h2, h3, h4, h5, h6,
ul, ol, dd, p, figure,
pre, table, fieldset, address, blockquote {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

// yanked from bootstrap 4's reboot.css
a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
button > span {
  pointer-events: none; // because webkit bug: https://bugs.webkit.org/show_bug.cgi?id=39620
}

ol, ul {
  padding-left: 0;
  margin-left: 1.5em;
  margin-left: ~'calc(1.5em + 1 * ((100vw - 300px) / 200))';
}

blockquote {
  position: relative;
  margin: 0 1em 2em 1em;
  // margin-left: 1em;
  // margin-right: 0;
  padding: 0.5em 0 0.5em 1.5em;

  border: solid @line-grey;
  border-width: 0 0 0 0.25em;

  &::before {
    position: absolute;
    display: block;
    font-family: @display-font-stack;
    font-style: normal;
    content: '\201C';
    font-size: 2.25em;
    top: 0.2em;
    left: -0.4em;
    width: 0.7em;
    height: 0.7em;
    line-height: 1.2;
    text-align: center;
    color: #fff;
    background-color: @line-grey;
    border-radius: 50%;
  }

  &.lg::before {
    font-size: 3em;
  }

  footer {
    display: block;
    padding-left: 3em;
    text-indent: -1.1em;
    font-style: italic;
  }
  cite {
    font-style: normal;
  }
}

dd {
  margin-left: @side-spacing;
}

.small {
  font-size: .85em;
}

// for funsies
::-moz-selection {
  color: #fff;
  background-color: @c3p-blue-dark;
  text-shadow: none;
}
::selection {
  color: #fff;
  background-color: @c3p-blue-dark;
  text-shadow: none;
}
