.callout {
    display: block;
    margin-top: 0;
    margin-bottom: 1.25rem;
    padding: 1.25rem 1.5rem 1.5rem;
    background-color: @black-6;
    
    & :last-child {
        margin-bottom: 0;
    }
}
.callout--attention {
    border: 2px solid @c3p-red-dark;
    color: @c3p-red-dark;
    background-color: lighten(@c3p-red-dark, 70%);
}

.callout--ctip {
    position: relative;
    // color: #0088cb;
    color: #005e9d;
    background-color: transparent;
    
    border: solid #005e9d;
    border-width: 2px 0;
    
    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
        left: 0;
        right: 0;
        height: 1px;
        background-color: #0088cb;
    }
    &::before {
        top: -4px;
    }
    &::after {
        bottom: -4px;
    }
}
