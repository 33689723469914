.c-info-boxes {
    margin: 1em 0 2em;
}
.c-info-box {
    display: inline-block;
    vertical-align: top;
    font-size: 0.85em;
    width: 100%;
    margin-top: 2em;
    
    & :last-child {
        margin-bottom: 0;
    }
}
.c-info-box__heading {
    font-family: @display-font-stack;
    font-size: 1.25em;
    margin-bottom: 0.75em;
    line-height: @heading-line-height + 0.2;
    letter-spacing: 0.02em;
    color: @c3p-blue-dark;
}
.c-info-box__subheading {
    display: block;
    margin-top: 0.3em;
    font-family: @body-font-stack;
    font-size: 0.75em;
    font-weight: normal;
    color: @black-55;
}



@media screen and (min-width: 40em) {
    .c-info-box {
        width: 49%;
        padding-right: 1.5em;
    }
}



@supports (display: grid) {
    .c-info-boxes {
        display: grid;
        grid-gap: 1.5em;
        grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
        margin: 2em 0;
    }
    .c-info-box {
        width: auto;
        margin-top: 0;
    }
}
