.nav-primary {
  display: block;
  font-size: 0.8em;
  margin: 0;
  list-style: none;
}
.nav-primary--header {
  display: none;
}

.nav-primary__item {
  display: inline-block;
  vertical-align: baseline;
  width: 100%;
  text-align: center;
}


.nav-primary__link {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0.8em 1em;
  color: @grey-darker;
  box-shadow: none;

  &::after {
    position: absolute;
    display: block;
    content: '';
    border: 0.8rem solid transparent;
    border-left-color: @c3p-green;
    top: 50%;
    left: -1rem;
    margin-top: -0.75rem;
    opacity: 0;
    transform: translateX(0);

    transition: opacity @t-snappy, transform @t-snappy;
  }

  &:hover::after,
  &:focus::after,
  &:active::after,
  &.active::after {
    opacity: 1;
    transform: translateX(0.5rem);
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    box-shadow: none;

    .nav-primary__title {
      color: @c3p-green;
    }
  }
}

.nav-primary__title {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-family: @display-font-stack;
  font-size: 1.35em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  transition: color @t-basic;
}


.nav-supplementary__item {
  margin-right: 1.75em;

  &:last-child {
    margin-right: 0;
  }
}


.nav-footer {
  position: relative;
  display: block;
  font-size: 0.8em;
  margin-bottom: 1em;
  width: 100%;
  .clearfix;


  > :first-child {
    margin-bottom: 1.25rem;
  }
}
.nav-footer__links {
  margin: 0;
  list-style: none;

  > li {
    display: inline-block;
    width: auto;
    margin: 0.3em 1em 0.3em 0;
  }
}
.nav-footer__link {
  margin: 0.3em 0;
}



@media screen and (min-width: 34em) {
  .nav-primary {
    margin: 3em 0 0 -0.75em;
  }
  .nav-primary__item {
    width: auto;
  }
  .nav-primary__link {
    &::after {
      border: 0.8rem solid transparent;
      border-bottom-color: @c3p-green;
      bottom: -0.8rem;
      left: 50%;
      margin-left: -0.8rem;
      transform: translateY(0);
    }

    &:hover::after,
    &:focus::after,
    &:active::after,
    &.active::after {
      transform: translateY(-0.75rem);
    }
  }
}
@media screen and (min-width: 53em) {
  .nav-primary {
    font-size: 0.85em;
    margin: 2.5em 0 0 -1em;
  }
}
@media screen and (min-width: 58em) {
  .nav-primary {
    font-size: 0.9em;
  }
  .nav-primary__link {
    padding: 1em 1.2em;
  }
}
