.l-main-content {
  display: block;
  z-index: 0;
  flex: 1 0 auto;
  min-height: 15em;

  box-shadow: inset 0 4em 3em -4em rgba(0,0,0,0.2);
}

.l-content-block {
  position: relative;
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: @container-width;
  .container-padding;
  .clearfix;
}
.l-content-block--flat {
  padding-top: 0;
  padding-bottom: 0;
}
.l-content-block--wide {
  margin-left: 0;
  margin-right: 0;
  max-width: none;
}

.l-reading-width {
  max-width: @reading-width;
  margin-left: auto;
  margin-right: auto;
}
.l-reading-width--narrow {
  max-width: 28rem;
}
// Deprecate this, since we basically always have l-reading-width elements centred:
.l-reading-width--centre,
.l-reading-width--center {
  margin-left: auto;
  margin-right: auto;
}
.l-reading-width--right {
  margin-left: auto;
}

.l-breakout {
  margin-left: calc(45% - 45vw);
  margin-right: calc(45% - 45vw);
}
