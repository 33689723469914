// for any gross fixes, last-minute hacks, etc.
// http://csswizardry.com/2013/04/shame-css/


// destroy animation where unwanted
@media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
        transition: none !important;
        animation: none !important;
    }
}







// TEMPORARY

// a[href="#"] {
//     position: relative;
//     outline: 1px dotted red !important;
// 
//     &::before {
//         position: absolute;
//         display: block;
//         content: 'this link currently goes nowhere';
//         font-size: 0.6rem;
//         font-family: @body-font-stack;
//         top: -0.4rem;
//         left: 0.25rem;
//         background-color: @c3p-red-dark;
//         color: white;
//         padding: 0.2em;
//         opacity: 0.6;
//     }
// }

.callout--commentary {
    position: relative;
    font-size: 0.75em;
    margin-top: 2em;
    padding-top: 1.5em;
    border: 2px dashed red;
    color: @c3p-red-dark;
    
    &::before {
        position: absolute;
        display: block;
        content: 'memo/note/thing';
        font-size: 0.8em;
        font-style: italic;
        top: -1.1em;
        padding: 0.25em 0.75em;
        background-color: @c3p-red-dark;
        color: #fff;
    }
}

.under-construction {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 6em;
    height: 6em;
    background-image: url('@{image-path}under-construction.gif');
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.5;
}
