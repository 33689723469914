// use <span> elements for these, not <i>

.icon {
  display: inline-block;
  vertical-align: baseline;
  width: 1em;
  height: 1em;
  margin: 0 0.15em -0.1em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.icon--lower {
  margin-bottom: -0.15em;
}
.icon--lg {
  font-size: 1.25em;
  margin: -0.05em 0 -0.2em;
}

.icon-arrow-down--grey-dark {
  background-image: url('@{image-path}icons/icon-arrow-down-grey.svg');
}
.icon-arrow-down--white {
  background-image: url('@{image-path}icons/icon-arrow-down-white.svg');
}

.icon-arrow-up--white {
  background-image: url('@{image-path}icons/icon-arrow-up-white.svg');
}

.icon-call--white {
  background-image: url('@{image-path}icons/icon-call-white.svg');
}

.icon-cart--blue {
  background-image: url('@{image-path}icons/icon-cart-blue.svg');
}
.icon-cart--green {
  background-image: url('@{image-path}icons/icon-cart-green.svg');
}
.icon-cart--white {
  background-image: url('@{image-path}icons/icon-cart-white.svg');
}

.icon-expand-toggle--white {
  background-image: url('@{image-path}icons/icon-expand-toggle-white.svg');
  background-size: 100%;
  background-position: 0 0;
}

.icon-menuburger--white {
  background-image: url('@{image-path}icons/icon-menuburger-white.svg');
}

// .icon-search {
  //     background-image: url('@{image-path}icons/icon-search.svg');
// }
.icon-search--white {
  background-image: url('@{image-path}icons/icon-search-white.svg');
}

.icon-download--white {
  background-image: url('@{image-path}icons/icon-download2-white.svg');
}
