.site-header {
  position: relative;
  z-index: @z-header;
  display: block;
  flex: 0 0 auto;
  width: 100%;
  padding-top: 0.5em;
  background-color: #fff;
  border: solid @c3p-green;
  border-width: 0 0 0.25em;
}

.header-logo-login {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.c3p-header-logo {
  flex: 1 1 auto;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding-right: 1em;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }
}
.c3p-header-logo__img {
  display: block;
  width: 100%;
  max-width: 24em;
  // negative margin to correct for optical alignment
  // ...fancy way of saying human visual precision is garbage I guess
  margin-left: -0.2em;
  min-height: 2em;
  max-height: 5em;
}
.login-wrapper {
  flex: 0 0 auto;
}


@media screen and (min-width: 21em) {
  .site-header {
    padding: 1em 0 0.25em;
  }
}
@media screen and (min-width: 34em) {
  .site-header {
    padding: 1em 0 0;
    margin-bottom: @header-border-height;
    border-width: 0;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      bottom: -@header-border-height;
      height: @header-border-height;
    }
    &::before {
      width: 100%;
      background-color: @c3p-green;
    }
    &::after {
      right: 0;
      background-color: @c3p-blue-dark;
    }
  }
  .header-logo-login {
    padding-top: 0.5rem;
  }
}

@media screen and (min-width: 65em) {
  .site-header {
    &::after {
      width: ~'calc( ((100vw - 60rem) / 2) + 4rem )';
    }
  }
}
