table {
  margin: 2em 0;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
}
tr {
  border: solid @line-grey;
  border-width: 1px 0 0;

  table:not(.no-bottom-border) &:last-of-type {
    border-width: 1px 0;
  }
  table:not(.no-stripes) &:nth-child(2n + 2) {
    // background-color: @black-6;
    background-color: hsla(0, 0%, 0%, 0.05);
  }
}
th,
td {
  padding: 0.75em 0.5em;
  vertical-align: baseline;

  &.keep-wide {
    min-width: 11em;
  }
}
th {
  background-color: hsla(0, 0%, 0%, 0.05);
}
td {
  border-top: 1px solid @line-grey;
  
  > :last-child {
    margin-bottom: 0;
  }
}

