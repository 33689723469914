.c-dl-inline {
    dt {
        float: left;
        font-weight: bold;
        margin-right: 0.35em;
    }
    dd {
        margin-left: 0;
    }
}
