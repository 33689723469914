.c-fancy-heading {
  position: relative;
  margin-bottom: 2rem;
}
.c-fancy-heading--with-img {
  margin: -2rem 0 2rem;
  margin: -2rem ~'calc(-50vw + 50%)' 2rem;
  .container-padding(2rem; 1.5em);
}
.c-fancy-heading__text {
  position: relative;
  display: block;
  margin: 0;
  width: 100%;
  color: inherit;

  & > :last-child {
    margin-bottom: 0;
  }
}
.c-fancy-heading__parent-name {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 0.3em;
  letter-spacing: 0.025em;
}
.c-fancy-heading__img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: @c3p-blue-dark;
}



@media screen and @breakpoint-lg {
  .c-fancy-heading {
    margin: 0 0 2rem;
    padding: 0;
    border-width: 0;
    text-align: left;
  }
  .c-fancy-heading--with-img {
    margin-top: -2.5rem;
    .container-padding(2rem; 2rem);
  }
  .c-fancy-heading__img {
    left: 0;
    right: 0;
  }
}
