#plyr-sprites {
  display: none;
  height: 0 !important;
}

.plyr {
  --plyr-font-size-large: 0.85rem;


  font-family: @body-font-stack;
  border-radius: @video-border-radius;
}
.plyr--video,
.plyr__video-wrapper,
.plyr__poster {
  background-color: transparent;
}
.plyr__control--overlaid {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
}
.plyr--video .plyr__controls {
  background: linear-gradient(rgba(0,0,0,0) 20%, rgba(24,32,36,0.8));
}
.plyr__progress input[type="range"],
.plyr__progress__buffer {
  width: 100%;
}
.plyr__volume input[type="range"] {
  -ms-flex-negative: 1;
  min-width: 0;
}


// c3p-blue
// @plyr-main: rgb(38, 142, 207);
// @plyr-main-t1: rgba(38, 142, 207, 0.8);
// @plyr-main-t2: rgba(38, 142, 207, 0.55);

// c3p-green
// @plyr-main: rgb(73, 147, 56);
// @plyr-main-t1: rgba(73, 147, 56, 0.85);
// @plyr-main-t2: rgba(73, 147, 56, 0.55);

// c3p-green-light
@plyr-main: rgb(89, 176, 57);
@plyr-main-t1: rgba(89, 176, 57, 0.85);
@plyr-main-t2: rgba(89, 176, 57, 0.55);


.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px @plyr-main-t2;
}
.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded=true] {
  background-color: @plyr-main;
}
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background-color: @plyr-main;
}
.plyr__control--overlaid {
  background-color: @plyr-main-t1;
}
.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
  background-color: @plyr-main;
}
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background-color: @plyr-main;
}
.plyr--full-ui input[type=range] {
  color: @plyr-main;
}
.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px @plyr-main-t2;
}
.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px @plyr-main-t2;
}
.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px @plyr-main-t2;
}

