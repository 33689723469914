[disabled] {
    cursor: not-allowed;
    
    input&,
    textarea& {
        background-color: @black-10;
        border-color: @black-10;
    }
}
form {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
input,
textarea,
select {
    font-family: inherit;
}
fieldset {
    display: block;
    margin-top: 2rem;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    border-width: 0;
}
legend {
    font-size: 1.2em;
    width: 100%;
    margin-bottom: 1.5em;
    padding-bottom: 0.25em;
    
    font-weight: bold;
    border-bottom: 1px solid @line-grey;
}

input,
textarea {
    padding: 0.4em 0.6em;
    line-height: inherit;
    width: 100%;
    border: 2px solid @black-20;
    border-radius: 0;
    transition: border @t-basic;
    
    &:focus {
        border-color: @black-80;
    }
    
    .error & {
        border-color: @input-error;
    }
}
input[type="checkbox"],
input[type="radio"] {
    position: absolute;
    width: auto;
    opacity: 0;
}
input[type="search"] {
    -webkit-appearance: none;
    border-radius: 0;
}
select {
    line-height: @body-line-height + 0.05;
    padding: 0.4em 1.75em 0.4em 0.6em;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    &::-ms-expand {
        display: none;
    }
    
    > option {
        position: relative;
        margin: 0;
        padding: 0;
        -webkit-appearance: menulist-button;
        
        &::after {
            position: absolute;
            display: block;
            content: attr(value string);
        }
    }
}

label {
    position: relative;
    display: inline-block;
    margin-bottom: 0.25em;
    width: 100%;
    line-height: inherit;
    
    border-top: 2px solid transparent; // for alignment
    
    .error & {
        color: @input-error;
    }
    
    
    // custom checkbox and radio inputs
    input[type="checkbox"] + &,
    input[type="radio"] + & {
        padding-left: 2em;
    }
    input[type="checkbox"] + &::before,
    input[type="radio"] + &::before {
        position: absolute;
        display: block;
        content: '';
        top: 0.2rem;
        left: 0;
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid @black-30;
        transition: border-color @t-basic;
    }
    input[type="checkbox"]:focus + &::before,
    input[type="radio"]:focus + &::before {
        border-color: @black-80;
    }
    input[type="radio"] + &::before {
        border-radius: 50%;
    }
    
    input[type="checkbox"] + &::after,
    input[type="radio"] + &::after {
        position: absolute;
        display: block;
        content: '';
        opacity: 0;
        transition: opacity @t-snappy;
    }
    input[type="checkbox"]:checked + &::after {
        opacity: 1;
        top: -0.15em;
        left: 0.5em;
        width: 0.7rem;
        height: 1.2rem;
        border: solid @black-80;
        border-width: 0 0.3em 0.3em 0;
        transform: rotate(45deg);
    }
    input[type="radio"]:checked + &::after {
        opacity: 1;
        top: 0.2rem;
        left: 0;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        border: 0.3em solid transparent;
        background-clip: content-box;
        background-color: @black-80;
    }
    
    
    small {
        font-style: italic;
        color: @black-40;
    }
}
.label-radio {
    display: block;
    width: auto;
    padding-top: 0;
    text-align: left;
    
    input {
        width: auto;
        margin-right: 0.5em;
    }
}
.label-radio--inline {
    display: inline-block;
}

.form-group {
    margin-bottom: 1.25rem;
}
.input-group {
    position: relative;
    display: inline-block;
    width: 100%;
}
.form-error {
    display: block;
    font-size: 0.9rem;
    margin-top: 0.25em;
    font-style: italic;
    color: @input-error;
    
    .dark-bg & {
        color: #fff;
    }
}

.input--short {
    max-width: 20em;
}



@media screen and (min-width: 35rem) {
    .form-group--horiz-align {
        margin-left: 31%;
        margin-left: ~'calc(30% + 1em)';
    }
    .form-group--horiz {
        .input-group {
            display: inline-block;
            vertical-align: top;
            width: 65%;
        }
        label {
            vertical-align: top;
            width: 30%;
            margin-right: 1em;
            padding-top: 0.4em;
            text-align: right;
        }
    }
    
    .btn-align {
        display: block;
        // margin-left: 30%;
        // margin-left: ~'calc(30% + 1em)';
    }
}
