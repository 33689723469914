.block-link {
    position: relative;
    display: inline-block;
    padding: 0.5em 2.75em 0.5em 1.25em;
    font-style: italic;
    text-transform: uppercase;
    text-align: center;
    box-shadow: none;
    color: #fff;
    background-image: none;
    background-color: @black-80;
    transition: background-color @t-basic;
    
    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        background-color: @black-60;
        
        &::after {
            right: 0.55em;
        }
    }
    
    &::after {
        .link-arrow;
        top: 50%;
        right: 0.7em;
        transform: translateY(-50%);
        transition: right @t-basic;
    }
}

.block-link--simple {
    padding: 0.5em 1.25em;
    &::after {
        content: none;
    }
}
.block-link--sm {
    font-size: 0.85em;
}
.block-link--lg {
    font-size: 1.25em;
}
.block-link--full {
    width: 100%;
}

.block-link--light {
    color: @black-80;
    background-color: #fff;
    line-height: @body-line-height - 0.3;
    
    &:hover,
    &:focus,
    &:active {
        background-color: @black-10;
    }
    
    &::after {
        .link-arrow-dark(@bg-size: 0.5em);
    }
}
.block-link--blue {
    background-color: @c3p-blue-dark;
    
    &:hover,
    &:focus,
    &:active {
        background-color: @c3p-blue;
    }
}
.block-link--green {
    background-color: @c3p-green;
    
    &:hover,
    &:focus,
    &:active {
        background-color: @c3p-green-light;
    }
}
.block-link--red {
    background-color: @c3p-red;
    
    &:hover,
    &:focus,
    &:active {
        background-color: @c3p-red-light;
    }
}
