.display-flex() {
    display: flex;
    // this is dumb but also the easiest way to make autoprefixer retain this flag
    // display: -webkit-flex;
}



.clearfix() {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}



.container-padding(@top: 2.5em; @bottom: 3em) {
    padding: @top 1em @bottom;
    padding-left: @side-spacing;
    padding-right: @side-spacing;
}


.link-arrow-base() {
    position: absolute;
    display: block;
    content: '';
    width: 1.4em;
    height: 1.4em;
    background-repeat: no-repeat;
    background-position: 60% center;
    border-radius: 50%;
}
.link-arrow(@font-size: 1em; @bg-size: 0.55em) {
    .link-arrow-base;
    font-size: @font-size;
    background-color: #fff;
    background-image: url('@{image-path}icons/icon-triangle.svg');
    background-size: @bg-size;
}
.link-arrow-dark(@font-size: 1em; @bg-size: 0.55em) {
    .link-arrow-base;
    font-size: @font-size;
    background-color: @black-80;
    background-image: url('@{image-path}icons/icon-triangle-white.svg');
    background-size: @bg-size;
}
