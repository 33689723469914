.c-back-to-top {
    position: fixed;
    display: block;
    z-index: @z-back-to-top;
    right: 0.5em;
    bottom: 1em;
    
    opacity: 1;
    transition: opacity @t-basic ease;
    
    &.hide {
        opacity: 0;
        pointer-events: none;
    }
}
.c-back-to-top__btn {
    display: block;
    width: 3em;
    height: 3em;
    
    border-radius: 50%;
    background-image: url('@{image-path}icons/icon-arrow-up-white.svg');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
    background-color: @c3p-green;
    opacity: 0.6;
    box-shadow: 0 0.1em 0.35em rgba(0,0,0,0.35);
    
    transition: opacity @t-basic;
    
    &:hover,
    &:focus,
    &:active {
        opacity: 1;
        box-shadow: 0 0.05em 0.35em rgba(0,0,0,0.35);
    }
}
// .c-back-to-top__text {
// 
// }
