@wbanner-padding: calc(50vw - (@container-width / 2));

.welcome-banner__heading {
  padding: 1.5rem @side-spacing;
  background-color: @c3p-blue-dark;
  background-image: linear-gradient(to right, @c3p-blue-darker, @c3p-blue-dark);
  color: white;
  text-align: center;

  h1 {
    margin: 0 0 0.7rem;
    padding: 0;
    font-size: 2.7rem;
  }
}

.welcome-banner__text {
  .l-reading-width();
  padding: 1.5rem @side-spacing;
}



@media screen and @breakpoint-lg {
  .welcome-banner {
    display: flex;
    flex-wrap: nowrap;
  }

  .welcome-banner__heading {
    flex: 1 1 90%;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: @wbanner-padding;
    padding-left: clamp(@side-spacing, @wbanner-padding, @wbanner-padding);

    h1 {
      min-width: (@container-width / 3.5);
    }
  }

  .welcome-banner__text {
    flex: 0 1 auto;
    align-self: center;
    margin: 0;
    max-width: 100%;
    padding-right: @wbanner-padding;
    padding-right: clamp(@side-spacing, @wbanner-padding, @wbanner-padding);
  }
}
