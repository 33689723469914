@video-border-radius: 0.25em;

video,
.c-video {
  position: relative;
  display: block;
  max-width: 100%;
}
.c-video {
  margin-bottom: 1.25rem;
}
.plyr {
  border-radius: @video-border-radius;
  box-shadow: @box-shadow-basic;
}

.plyr-overlay {
  padding: 1em;
}


@media screen and (min-width: 34em) {
  .plyr-overlay {
    position: absolute;
    z-index: 1;
    display: flex;
    padding: 0;
    top: 1em;
    left: 1em;
    bottom: 3.25em;
    width: 35%;

    font-size: 0.9em;
    opacity: 0.9;

    > * {
      margin: auto;
    }

    // Clicks that happen on .plyr-overlay should pass through to the video below,
    // but any clicks on its children elements should not
    pointer-events: none;

    * {
      pointer-events: auto;
    }
  }

}
