.l-grid {
  margin: 2em 0 2.5em;
  list-style: none;
}
.l-grid__item {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0.75em;
  width: 100%;

  @media screen and (min-width: 38em) {
    width: 49%;
  }

  .l-grid--3up & {
    @media screen and (min-width: 30em) {
      width: 49%;
    }
    @media screen and (min-width: 64em) {
      width: 32%;
    }
  }
  .l-grid--4up & {
    @media screen and (min-width: 26em) {
      width: 49%;
    }
    @media screen and (min-width: 60em) {
      width: 25%;
    }
  }
}



@supports (display: grid) {
  .l-grid {
    display: grid;
    grid-gap: 1.75em;
    grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  }
  .l-grid--3up {
    grid-gap: 1.5em;
    grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
  }
  .l-grid--4up {
    grid-gap: 1.25em;
    grid-template-columns: repeat(auto-fit, minmax(9em, 1fr));
  }

  .l-grid__item,
  .l-grid--3up .l-grid__item,
  .l-grid--4up .l-grid__item {
    padding: 0;
    width: auto;
  }
}
