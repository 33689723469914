@font-path: "/static/fonts/";
@opt-font-path: "/static/fonts/subsetted/";

.font-mixin(@font-family; @font-weight; @font-style; @font-stretch; @font-src) {
    font-family: @font-family;
    font-weight: @font-weight;
    font-style: @font-style;
    font-stretch: @font-stretch;
    src: url('@{opt-font-path}@{font-src}-subset.woff2') format('woff2'),
         url('@{opt-font-path}@{font-src}-subset.zopfli.woff') format('woff'),
         url('@{opt-font-path}@{font-src}-subset.ttf') format('truetype');
    font-display: swap;
}

@font-face { .font-mixin('OpenSans'; normal; normal; normal; 'OpenSans-Regular'); }
@font-face { .font-mixin('OpenSans'; normal; italic; normal; 'OpenSans-Italic'); }
@font-face { .font-mixin('OpenSans'; bold;   normal; normal; 'OpenSans-Bold'); }
@font-face { .font-mixin('OpenSans'; bold;   italic; normal; 'OpenSans-BoldItalic'); }

@font-face { .font-mixin('Oswald'; 300;    normal; normal; 'Oswald-Light'); }
@font-face { .font-mixin('Oswald'; normal; normal; normal; 'Oswald-Regular'); }
@font-face { .font-mixin('Oswald'; bold;   normal; normal; 'Oswald-SemiBold'); }
