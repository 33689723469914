//
// Fun fact: CSS multi-column layout is pretty great for flowing inline content,
// and a little bit horrible for flowing block content. It's still the best
// solution to the particular problem of filling columns without having to specify
// a height for the parent element.
//
// If the children elements you're flowing into columns are blocks and you don't
// want them to break between columns, make sure to declare both of these on the
// children elements:
//
//     break-inside: avoid; (autoprefixer will fill in the rest in this case)
//     overflow: hidden; (or else you will get Firefox woes)
//


.c-site-map,
.c-site-map__category-list {
    margin-left: 0;
    list-style: none;
}
.c-site-map {
    margin-top: 2em;
}
.c-site-map--header {
    margin-top: 1em;
    color: #fff;
    
    a {
        color: #fff;
    }
}
.c-site-map__category {
    padding-bottom: 1.5rem;
    width: 100%;
}
.c-site-map__category-heading {
    font-size: 1.3em;
    letter-spacing: 0.01em;
    margin: 0;
}
.c-site-map__category-list {
    margin-bottom: 0;
    padding-top: 0.75em;
    font-size: 0.85em;

    li {
        padding-bottom: 0.15em;
        padding-left: 0.8em;
        text-indent: -0.8em;
    }
}
.c-site-map__category-link,
.c-site-map__list-link {
    box-shadow: none;
    
    &:hover,
    &:focus,
    &:active {
        box-shadow: inset 0 -2px 0 0 @c3p-blue-light;
    }
}



@media screen and (min-width: 26em) {
    .c-site-map {
        clear: none;
        column-count: 2;
    }
    .c-site-map__category {
        display: block;
        padding-right: 1.5em;
        float: none;
        width: auto;
        overflow: hidden;
        break-inside: avoid;
    }
}
@media screen and (min-width: 40em) {
    .c-site-map {
        column-count: 3;
    }
}
@media screen and @breakpoint-lg {
    .c-site-map {
        column-count: 4;
    }
}
