.skip-to-content {
    position: absolute;
    display: block;
    z-index: @z-header + 1;
    top: 0;
    left: -9999px;
    padding: 0.25em 0.5em;
    background-color: #fff;
    border: 2px solid @c3p-green;
    
    &:focus,
    &:active {
        left: 0;
    }
}
