a {
    position: relative;
    z-index: 1;
    
    word-break: break-word;
    word-wrap: break-word;
    text-decoration: none;
    color: @c3p-blue-dark;
    text-decoration-skip-ink: auto;
    transition-duration: @t-basic;
    transition-property: color, box-shadow;
    
    box-shadow: inset 0 -2px 0 0 lighten(@c3p-blue-light, 20%);
    // transition: box-shadow @t-basic;
    
    &:hover,
    &:focus,
    &:active {
        box-shadow: inset 0 -2px 0 0 @c3p-blue-light;
    }
    
    &[href^="tel"] {
        box-shadow: none;
    }
    
    &.light {
        color: #fff;
        box-shadow: inset 0 -2px 0 0 @c3p-blue-light;
        
        &:hover,
        &:focus,
        &:active {
            box-shadow: inset 0 -2px 0 0 lighten(@c3p-blue-light, 20%);
        }
    }
}

[href*="https://"],
[href*="http://"] {
    padding-right: 0.9em;
    background-image: url('@{image-path}icons/icon-external-blue.svg');
    background-repeat: no-repeat;
    background-size: 0.75em;
    background-position: right 45%;
    
    &.light {
        background-image: url('@{image-path}icons/icon-external-white.svg');
    }
}

[href^="tel"] {
    white-space: nowrap;
}


// Link boxes
.c-link-boxes__heading {
  text-align: center;
}
.c-link-boxes__page-link {
  display: block;
  width: 100%;
  margin-top: 1.5em;
  text-align: center;
}

.c-link-boxes {
  font-size: 0.85em;
  list-style: none;
  margin: 0;
}

.c-link-box {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: left;
}
// todo: share some declarations with .c-download-button
.c-link-box__link {
  display: block;
  margin: 0.5em 0;
  padding: 1em 1em 1.25em;
  color: @grey-darker;

  border: 1px solid #fff;
  background-image: none;
  background-color: @grey1;
  box-shadow: @box-shadow-transparent;
  transform: translateY(0);

  &:extend(.link-box-transitions);

  &:hover,
  &:focus,
  &:active {
    color: @grey-darker;
    box-shadow: none;

    border-color: @line-grey;
    background-color: #fff;
    box-shadow: @box-shadow-basic;
    transform: translateY(-0.2em);
  }
}
.c-link-box__img {
    display: block;
    margin: -1em -1em 1em;
    padding-bottom: 50%;
    background-repeat: no-repeat;
    background-position: center;
    // background-size: 100%;
    background-size: cover;
    border: 3px solid #fff;
    border-width: 0 0 3px;

    &.link-box-pdf-bg {
        background-position: center top 1em;
        background-color: @grey2;
        background-size: 80%;
    }

    &.c3p_as_witness_intervener {
      background-image: url('@{image-path}content/C3PAsWitness-Intervener.jpg');
    }
    &.c3p_legal_overviews {
      background-image: url('@{image-path}content/C3PLegalOverviews.jpg');
    }
    &.case_law_updates {
      background-image: url('@{image-path}content/CaseLawUpdates.jpg');
    }
    &.community_impact_statements {
      background-image: url('@{image-path}content/CommunityImpactStatements.jpg');
    }
    &.court_advocacy {
      background-image: url('@{image-path}content/CourtAdvocacy.jpg');
    }
    &.external_resources {
      background-image: url('@{image-path}content/ExternalResources.jpg');
    }
    &.legal_research_projects {
      background-image: url('@{image-path}content/LegalResearchProjects.jpg');
    }
    &.news {
      background-image: url('@{image-path}content/News.jpg');
    }
    &.resources_from_crowns {
      background-image: url('@{image-path}content/ResourcesFromCrownAttorneys.jpg');
    }
    &.training_other {
      background-image: url('@{image-path}content/OtherProfessionalDevelopment.jpg');
    }
    &.training_videos {
      background-image: url('@{image-path}content/TraumaInformedApproach-Videos.jpg');
    }
    &.victim_impact_statements {
      background-image: url('@{image-path}content/VictimImpactStatements.jpg');
    }
}

.c-link-box__img--tall {
  padding-bottom: 65%;
}
.c-link-box__img--news {
  padding-bottom: 68%;

  // for ease of updating, news/press release images declared in
  // components/news-release-images.less
}
.c-link-box__heading-wrapper {
  & + p {
    padding-top: 0.65em;
    margin-top: 0.65em;
    border-top: 1px solid @line-grey;
  }

  & + p:empty {
    border-width: 0;
  }
}
.c-link-box__heading {
  margin-bottom: 0;
  font-weight: normal;
}
.c-link-box__link-info {
  margin-top: 0.5em;
  font-size: 0.85em;
  font-style: italic;

  //.c-link-box__link[target="_blank"] & {
  //  padding-left: 1.6em;
  //  background-image: url('@{image-path}icons/icon-external-blue-dark.svg');
  //  background-repeat: no-repeat;
  //  background-size: 1.15em;
  //  background-position: left 55%;
  //}
  //.c-link-box__link[href$=".pdf"] & {
  //  padding-left: 1.5em;
  //  background-image: url('@{image-path}icons/icon-download2-blue-dark.svg');
  //  background-repeat: no-repeat;
  //  background-size: 1.2em;
  //  background-position: left center;
  //}
}
.c-link-box__desc {
  margin-bottom: 0;

  & > p:last-child {
    margin-bottom: 0;
  }
}
.c-link-box__time {
  display: block;
  font-weight: normal;
  font-size: 0.85em;
  margin: 0.5em 0 0.35em;
}



@media screen and (min-width: 34em) {
  .c-link-box {
    width: 48%;
  }
  .c-link-box__link {
    margin: 0.25em;
  }
}
@media screen and @breakpoint-lg {
  .c-link-boxes__heading {
    text-align: left;
  }
  .c-link-boxes__page-link {
    position: absolute;
    margin-top: 0;
    top: 0;
    right: 0;
    width: auto;
  }
}
@media screen and (min-width: 60em) {
  .c-link-boxes--narrow .c-link-box {
    width: 32%;
  }
}



@supports (display: grid) {
  .c-link-boxes {
    display: grid;
    grid-gap: 1.5em;
    grid-template-columns: 1fr;
  }
  .c-link-boxes--narrow {
    grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));
  }
  .c-link-box,
  .c-link-boxes--narrow .c-link-box {
    width: auto;
  }
  .c-link-box__link {
    height: 100%;
    margin: 0;
  }



  @media screen and (min-width: 30em) {
    .c-link-boxes {
      // grid-template-columns: repeat(2, 1fr);
      grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
    }
    .c-link-boxes--narrow {
      grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));
    }
  }
}
