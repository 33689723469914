.c-download-buttons {
    margin: 0.5em 0 1.5em;
    padding: 0.5em 0;
    border: solid @line-grey;
    border-width: 1px 0;
    text-align: center;
}
.c-download-buttons--simple {
    margin: 0 0 1em;
    padding: 0;
    border-width: 0;
}

// todo: share some declarations with .c-link-box
.c-download-button {
    display: block;
    margin: 0.75em auto;
    padding: 0.5em 0.75em;
    max-width: 24em;
    text-align: center;
    
    border: 1px solid #fff;
    background-color: @black-6;
    background-image: none;
    box-shadow: @box-shadow-transparent;
    transform: translateY(0);
    
    transition: 
        border-color @t-snappy,
        background-color @t-basic,
        transform @t-basic,
        box-shadow @t-slow;
    
    &:hover,
    &:focus,
    &:active {
        border-color: @line-grey;
        background-color: #fff;
        box-shadow: @box-shadow-basic;
        transform: translateY(-0.2em);
        
        .c-download-button__dl-text {
            background-color: @c3p-blue-dark;
        }
    }
    
    .icon {
        margin-right: -0.5em;
    }
}
.c-download-button--site-banner {
    margin: 0;
    width: 100%;
    max-width: none;
}

.c-download-button__img-wrapper {
    display: block;
}
.c-download-button__img {
    display: none;
    margin: 0.5em auto 0.3em;
    box-shadow: @box-shadow-sm;
    width: 100%;
    max-width: 10em;
    max-height: 10em;
    
    .c-download-button--site-banner & {
        display: block;
        max-width: 100%;
    }
}
.c-download-button__dl-text {
    display: block;
    width: 100%;
    background-color: @c3p-blue-dark-transparent;
    color: #fff;
    padding: 0.25em 0.5em;
    font-size: 0.85em;
    text-transform: uppercase;
    font-style: italic;
    transition: background-color @t-basic;
}
.c-download-button__outer-desc {
    font-size: 0.8em;
    margin: 0 1px 1px;
    padding: 0.75em 0.75em 0.5em;
    text-align: left;
    border: solid @black-6;
    border-width: 1px;
}



@media screen and (min-width: 20em) {
    .c-download-button {
        display: inline-block;
        vertical-align: top;
        margin: 0.75em 0.6em;
        padding: 0.75em 1em;
        min-width: 9.5em;
        max-width: 11.5em;
    }
    .c-download-button--site-banner {
        margin: 0;
        min-width: none;
        max-width: none;
    }
    .c-download-button__img-wrapper {
        display: inline-block;
    }
    .c-download-button__img {
        display: block;
    }
    .c-download-button__dl-text {
        font-size: 0.7em;
    }
    .c-download-button__desc {
        display: block;
        font-size: 0.85em;
        margin: 0.5em 0 0.25em;
    }
}
