.news-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  list-style: none;
}

.news-nav__item {
  margin: 0;
  flex: 1 0 50%;
  border: solid @line-grey;
}
.news-nav__item--prev {
  text-align: right;
  border-width: 0 1px 0 0;
}
.news-nav__item--next {
  border-width: 0;
}
.news-nav__item--archive {
  flex: 1 0 100%;
  border-width: 1px 0 0;
  text-align: center;
}

.news-nav__link {
  position: relative;
  display: block;
  padding: 0.75em 1.25em;
  box-shadow: none;

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 1em;
    height: 1em;
    top: 50%;
    margin-top: -0.4em;
    border: solid @grey5;
    border-width: 2px 2px 0 0;
    transition: border-color @t-basic;
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;

    &::after {
      border-color: @grey-darker;
    }
  }
}
.news-nav__link--prev {
  &::after {
    left: 0.4em;
    transform: rotate(-135deg);
  }
}
.news-nav__link--next {
  &::after {
    right: 0.4em;
    transform: rotate(45deg);
  }
}
.news-nav__link--archive {
  &::after {
    content: none;
  }
}
.news-nav__text {
  transition-duration: @t-basic;
  transition-property: color, box-shadow;
  box-shadow: inset 0 -2px 0 0 lighten(@c3p-blue-light, 20%);

  a:hover &,
  a:focus &,
  a:active & {
    box-shadow: inset 0 -2px 0 0 @c3p-blue-light;
  }
}
