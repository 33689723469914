.select-wrapper {
    line-height: inherit;
    width: 100%;
    border: 2px solid @black-15;
    
    // bg image:
    .icon-arrow-down--grey-dark;
    background-repeat: no-repeat;
    background-size: 1em;
    background-position: right 0.4em center;
    
    transition: border @t-basic;
    
    &.short {
        max-width: 20em;
    }
    
    &:focus,
    &:focus-within {
        border-color: @black-80;
    }
    
    .error & {
        border-color: @input-error;
    }
}
