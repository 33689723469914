// .u-clearfix
//
// References the clearfix mixin, in case clearfixing with a class in the markup is more convenient.
//
// Style guide: utilities.clearfix
.u-clearfix {
  .clearfix();
}

// .u-relative
//
// Forces relative positioning.
// Useful for declaring a positioning parent.
//
// Style guide: utilities.position.relative
.u-relative {
  position: relative !important;
}
// .u-static
//
// Forces static positioning.
// Useful for ensuring an element isn't a positioning parent.
//
// Style guide: utilities.position.static
.u-static {
  position: static !important;
}

.u-block {
  display: block;
}

.u-flex {
  display: flex;
}
.u-flex--center {
  align-items: center;
}
.u-flex__item {
  flex: 0 1 auto;
}
.u-flex__item--0 {
  flex: 0 0 auto;
}
.u-flex__item--1 {
  flex: 1 1 auto;
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// HIDING ELEMENTS
//

// .u-vh, .u-visually-hidden
//
// Removes an element from view and from the flow, without making it inaccessible to screen readers.
// <https://medium.com/@matuzo/writing-css-with-accessibility-in-mind-8514a0007939>
//
// Style guide: utilities.hide.visually
.u-vh,
.u-hidden-visually,
.u-visually-hidden {
  // Remove the item from normal flow
  position: absolute !important;
  // Workaround for falsely pronounced, smushed text
  white-space: nowrap !important;
  // Set it to the smallest possible size
  // (some screen readers ignore elements with zero height and width)
  width: 1px !important;
  height: 1px !important;
  // Hide overflowing content after resizing
  overflow: hidden !important;
  // Reset any property that may change the elements size
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  // Clipping defines what part of an element should be displayed.
  // Deprecated clip property for older browsers
  clip: rect(0 0 0 0);
  // clip-path for newer browsers. inset(50%) defines an
  // inset rectangle that makes the content disappear. 
  clip-path: inset(50%); 
  // It seems like at the moment nobody is quite sure why margin: -1px is there.
  // On top of that it seems to cause issues
  // (see: https://github.com/h5bp/html5-boilerplate/issues/1985).
  // margin: -1px;
}


// To hide an existing element completely (both from view and from screenreaders), use the [hidden] attribute.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/hidden



// -----------------------------------------------------------------------------------------------------------------------------------------
// COSMETIC UTILITIES
//

.u-underline {
  padding-bottom: 0.15em;
  border-bottom: 1px solid @line-grey !important;
}
.u-underline--light {
  padding-bottom: 0.15em;
  border-bottom: 1px solid rgba(255,255,255,0.7) !important;
}
.u-top-border {
  border-top: 1px solid @line-grey !important;
}

.u-drop-shadow {
  box-shadow: @box-shadow-basic;
}

.u-shadow-inset-top {
  box-shadow: inset 0 4em 3em -4em rgba(0,0,0,0.2);
}
.u-shadow-inset-bottom {
  box-shadow: inset 0 -4em 3em -4em rgba(0,0,0,0.2);
}
.u-shadow-inset-double {
  box-shadow: inset 0 4em 3em -4em rgba(0,0,0,0.2), inset 0 -4em 3em -4em rgba(0,0,0,0.2);
}

.u-textshadow-dark {
  text-shadow:
    1px 1px 2px black,
    -1px 1px 2px black,
    1px -1px 2px black;
}

.u-fontsize-sm {     font-size: 0.85em !important; }
.u-fontsize-normal { font-size: 1em !important; }

.u-weight-normal { font-weight: normal !important; }
.u-weight-bold {   font-weight: bold !important; }
.u-style-normal {  font-style: normal !important; }
.u-style-italic {  font-style: italic !important; }

.u-uppercase {     text-transform: uppercase !important; }

.u-text-center,
.u-text-centre {   text-align: center !important; }
.u-text-left {     text-align: left !important; }
.u-text-right {    text-align: right !important; }

.u-text-blue {     color: @c3p-blue-dark !important; }
.u-text-green {    color: @c3p-green !important; }
.u-text-red {      color: @c3p-red !important; }
.u-text-grey {     color: @grey6 !important; }
.u-text-grey-dark { color: @grey-darker !important; }
.u-text-white {    color: #fff !important; }


// .u-fake-italic
//
// This hackery is for the Oswald typeface ONLY, because it lacks a proper italic/oblique font.
// Example usage for a multi-line heading as follows:
//
// ```html
// <h1><cite>
//     <span class="u-fake-italic">Abducted</span> <span class="u-fake-italic">then</span>
//     <span class="u-fake-italic">Murdered</span> <span class="u-fake-italic">Children:</span>
//     <span class="u-fake-italic">A Canadian</span> <span class="u-fake-italic">Study</span>
// </cite></h1>
// ```
//
// Keep the `<cite>` or `<em>` or `<i>` tag for proper semantics, whichever you need, and
// break the text up per word if it wraps at small viewport sizes. Otherwise (since the skew
// transformation only works on a block or inline-block element), the left margin will also
// look skewed, and the more lines the heading wraps to, the worse the skewage shall be.
// For example:
//
// Markup:
// <p class="u-underline">Don’t do this:</p>
// <h2 style="max-width: 10em">
//   <span class="u-fake-italic">This is an example of a multi-line very long heading that gets skewed in an undesirable fashion</span>
// </h2>
// <p class="u-underline">Do this:</p>
// <h2 style="max-width: 10em">
//   <span class="u-fake-italic">This is an</span>
//   <span class="u-fake-italic">example of</span>
//   <span class="u-fake-italic">a multi-line</span>
//   <span class="u-fake-italic">very long</span>
//   <span class="u-fake-italic">heading that is</span>
//   <span class="u-fake-italic">broken up to</span>
//   <span class="u-fake-italic">avoid skewing</span>
// </h2>
//
// Style guide: utilities.cosmetic.fake-italic
.u-fake-italic {
  display: inline-block;
  font-style: normal !important;
  transform: skewX(-7deg);
  letter-spacing: -0.005em;
  margin-right: 0.025em;
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// WIDTH CONTROLS
//

.u-max-width-none { max-width: none !important; }
.u-max-width-xs {   max-width: 6em !important; }
.u-max-width-sm {   max-width: 12em !important; }
.u-max-width-md {   max-width: 18em !important; }
.u-max-width-lg {   max-width: 26em !important; }
.u-max-width-xl {   max-width: 36em !important; }



// Utility spacing
//
// Quick example classes:
// - `u-mt-lg`: apply large top margin
// - `u-px-sm`: apply a small amount of padding on the left and right
// - `u-p-md`: apply medium padding to all four sides
// - `u-mb-0`: get rid of bottom margin
//
// Available measures:
// - `xs`: 0.25em
// - `sm`: 0.5em
// - `md`: 1.25em
// - `lg`: 2em
// - `xl`: 3em
//
// Margin(s) or padding can apply in one direction:
// - `mt/pt`: margin-top/padding-top
// - `mr/pr`: margin-right/padding-right
// - `mb/pb`: margin-bottom/padding-bottom
// - `ml/pl`: margin-left/padding-left
//
// Or they can be two-dimensional:
// - `mx/px`: applies spacing along x-axis
// - `my/py`: applies spacing along y-axis
//
// Or they can be applied in all four directions with just `m` or `p`.
//
// Style guide: utilities.spacing

@u-xs-sp: 0.25em;
@u-sm-sp: 0.5em;
@u-md-sp: 1em;
@u-lg-sp: 2em;
@u-xl-sp: 3em;

.u-m-0 {    margin: 0; }
.u-m-xs {   margin: @u-xs-sp; }
.u-m-sm {   margin: @u-sm-sp; }
.u-m-md {   margin: @u-md-sp; }
.u-m-lg {   margin: @u-lg-sp; }
.u-m-xl {   margin: @u-xl-sp; }

.u-mx-0 {   margin-left: 0;         margin-right: 0; }
.u-mx-xs {  margin-left: @u-xs-sp;  margin-right: @u-xs-sp; }
.u-mx-sm {  margin-left: @u-sm-sp;  margin-right: @u-sm-sp; }
.u-mx-md {  margin-left: @u-md-sp;  margin-right: @u-md-sp; }
.u-mx-lg {  margin-left: @u-lg-sp;  margin-right: @u-lg-sp; }
.u-mx-xl {  margin-left: @u-xl-sp;  margin-right: @u-xl-sp; }

.u-my-0 {   margin-top: 0;          margin-bottom: 0; }
.u-my-xs {  margin-top: @u-xs-sp;   margin-bottom: @u-xs-sp; }
.u-my-sm {  margin-top: @u-sm-sp;   margin-bottom: @u-sm-sp; }
.u-my-md {  margin-top: @u-md-sp;   margin-bottom: @u-md-sp; }
.u-my-lg {  margin-top: @u-lg-sp;   margin-bottom: @u-lg-sp; }
.u-my-xl {  margin-top: @u-xl-sp;   margin-bottom: @u-xl-sp; }

.u-mt-0 {   margin-top: 0; }
.u-mt-xs {  margin-top: @u-xs-sp; }
.u-mt-sm {  margin-top: @u-sm-sp; }
.u-mt-md {  margin-top: @u-md-sp; }
.u-mt-lg {  margin-top: @u-lg-sp; }
.u-mt-xl {  margin-top: @u-xl-sp; }

.u-mr-0 {   margin-right: 0; }
.u-mr-xs {  margin-right: @u-xs-sp; }
.u-mr-sm {  margin-right: @u-sm-sp; }
.u-mr-md {  margin-right: @u-md-sp; }
.u-mr-lg {  margin-right: @u-lg-sp; }
.u-mr-xl {  margin-right: @u-xl-sp; }

.u-mb-0 {   margin-bottom: 0; }
.u-mb-xs {  margin-bottom: @u-xs-sp; }
.u-mb-sm {  margin-bottom: @u-sm-sp; }
.u-mb-md {  margin-bottom: @u-md-sp; }
.u-mb-lg {  margin-bottom: @u-lg-sp; }
.u-mb-xl {  margin-bottom: @u-xl-sp; }

.u-ml-0 {   margin-left: 0; }
.u-ml-xs {  margin-left: @u-xs-sp; }
.u-ml-sm {  margin-left: @u-sm-sp; }
.u-ml-md {  margin-left: @u-md-sp; }
.u-ml-lg {  margin-left: @u-lg-sp; }
.u-ml-xl {  margin-left: @u-xl-sp; }


.u-p-0 {    padding: 0; }
.u-p-xs {   padding: @u-xs-sp; }
.u-p-sm {   padding: @u-sm-sp; }
.u-p-md {   padding: @u-md-sp; }
.u-p-lg {   padding: @u-lg-sp; }
.u-p-xl {   padding: @u-xl-sp; }

.u-px-0 {   padding-left: 0;        padding-right: 0; }
.u-px-xs {  padding-left: @u-xs-sp; padding-right: @u-xs-sp; }
.u-px-sm {  padding-left: @u-sm-sp; padding-right: @u-sm-sp; }
.u-px-md {  padding-left: @u-md-sp; padding-right: @u-md-sp; }
.u-px-lg {  padding-left: @u-lg-sp; padding-right: @u-lg-sp; }
.u-px-xl {  padding-left: @u-xl-sp; padding-right: @u-xl-sp; }

.u-py-0 {   padding-top: 0;         padding-bottom: 0; }
.u-py-xs {  padding-top: @u-xs-sp;  padding-bottom: @u-xs-sp; }
.u-py-sm {  padding-top: @u-sm-sp;  padding-bottom: @u-sm-sp; }
.u-py-md {  padding-top: @u-md-sp;  padding-bottom: @u-md-sp; }
.u-py-lg {  padding-top: @u-lg-sp;  padding-bottom: @u-lg-sp; }
.u-py-xl {  padding-top: @u-xl-sp;  padding-bottom: @u-xl-sp; }

.u-pt-0 {   padding-top: 0; }
.u-pt-xs {  padding-top: @u-xs-sp; }
.u-pt-sm {  padding-top: @u-sm-sp; }
.u-pt-md {  padding-top: @u-md-sp; }
.u-pt-lg {  padding-top: @u-lg-sp; }
.u-pt-xl {  padding-top: @u-xl-sp; }

.u-pr-0 {   padding-right: 0; }
.u-pr-xs {  padding-right: @u-xs-sp; }
.u-pr-sm {  padding-right: @u-sm-sp; }
.u-pr-md {  padding-right: @u-md-sp; }
.u-pr-lg {  padding-right: @u-lg-sp; }
.u-pr-xl {  padding-right: @u-xl-sp; }

.u-pb-0 {   padding-bottom: 0; }
.u-pb-xs {  padding-bottom: @u-xs-sp; }
.u-pb-sm {  padding-bottom: @u-sm-sp; }
.u-pb-md {  padding-bottom: @u-md-sp; }
.u-pb-lg {  padding-bottom: @u-lg-sp; }
.u-pb-xl {  padding-bottom: @u-xl-sp; }

.u-pl-0 {   padding-left: 0; }
.u-pl-xs {  padding-left: @u-xs-sp; }
.u-pl-sm {  padding-left: @u-sm-sp; }
.u-pl-md {  padding-left: @u-md-sp; }
.u-pl-lg {  padding-left: @u-lg-sp; }
.u-pl-xl {  padding-left: @u-xl-sp; }
