.c-lead-ins {
    position: relative;
}
.c-lead-ins__heading {
    text-align: center;
}
.c-lead-ins__link-all {
    display: block;
    width: 100%;
    margin-top: 1.5em;
    text-align: center;
}
.c-lead-ins__list {
    list-style: none;
    margin: 0;
    text-align: center;
}
.c-lead-ins__item {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    text-align: left;
}
.c-lead-ins__link {
    display: block;
    margin: 0.5em 0;
    padding: 1em;
    
    box-shadow: none;
    border: 1px solid transparent;
    background-color: @black-6;
    box-shadow: @box-shadow-transparent;
    
    transition: border-color @t-basic, background-color @t-basic, box-shadow @t-slow;
    
    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        border-color: @line-grey;
        background-color: #fff;
        box-shadow: @box-shadow-basic;
    }
}
.c-lead-ins__img {
    display: block;
    margin-bottom: 1em;
    padding-bottom: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    
    &.img1 { background-image: url('@{image-path}egnews1.jpg'); }
    &.img2 { background-image: url('@{image-path}egnews2.jpg'); }
    &.img3 { background-image: url('@{image-path}egnews3.jpg'); }
}
.c-lead-ins__desc {
    margin-bottom: 0;
    font-weight: bold;
}
.c-lead-ins__time {
    display: block;
    font-weight: normal;
    font-size: 0.85em;
    margin: 0.5em 0 0.35em;
}



@media screen and (min-width: 30em) {
    .c-lead-ins__item {
        width: 48%;
    }
    .c-lead-ins__link {
        margin: 0.25em;
    }
}
@media screen and (min-width: 30em) and (max-width: 47.9375em) {
    .c-lead-ins__item--first {
        width: 100%;
        
        .c-lead-ins__link {
            .display-flex;
        }
        .c-lead-ins__img {
            flex-basis: 50%;
            padding-bottom: 25%;
            margin-right: 1.25em;
            margin-bottom: 0;
            background-position: center 5%;
        }
        .c-lead-ins__desc {
            flex-basis: 50%;
        }
        .c-lead-ins__time {
            margin-top: 0;
        }
    }
}
@media screen and (min-width: 48em) {
    .c-lead-ins__heading {
        text-align: left;
    }
    .c-lead-ins__link-all {
        position: absolute;
        margin-top: 0;
        top: 0;
        right: 0;
        width: auto;
    }
    .c-lead-ins__item {
        width: 32%;
    }
}



@supports (display: grid) {
    .c-lead-ins__list {
        display: grid;
        grid-gap: 1.5em;
    }
    .c-lead-ins__item {
        width: auto;
    }
    .c-lead-ins__link {
        height: 100%;
        margin: 0;
    }
    
    
    
    @media screen and (min-width: 30em) {
        .c-lead-ins__list {
            grid-template-columns: 1fr 1fr;
        }
    }
    @media screen and (min-width: 30em) and (max-width: 47.9375em) {
        .c-lead-ins__item--first {
            grid-column: ~'1 / -1';
        }
    }
    @media screen and (min-width: 48em) {
        .c-lead-ins__list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
